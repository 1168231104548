import React from "react";
import SEO from "../components/SEO";
import BlogLinkCard from "../components/Blog/BlogLinkCard";
import { graphql } from "gatsby";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Img from "gatsby-image";

export default function Index({ data }) {
  return (
    <>
      <SEO
        title={"Pluggdriven"}
        description="Pluggdriven.se är en sida med allt kopplat till plugg och studier."
      />
      <Navbar />
      <div className="topSection">
        <Img fluid={data.homeTop.childImageSharp.fluid} className="imageTop" loading="eager"/>
        <div className="imageTopText">
          <h2>Förbättra dina studier</h2>
          <p>Lär dig mer om matematik, fysik och kemi.</p>
        </div>
        <div className="waveHome">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>

      <div className="siteBackground">
        <br />
        <br />

        <div className="wrapper">
          <BlogLinkCard />
        </div>
      </div>
      <Footer dark={true} />
    </>
  );
}

export const pageQuery = graphql`
  query home {
    homeTop: file(relativePath: { eq: "topImage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  
  }
`;
