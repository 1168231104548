import React from "react";
import { Link } from "gatsby";

export default class BlogLinkCard extends React.Component {
  render() {
    return (
      <div className="blog-link-card">
        <div className="card-content">
          <h2>Vill du lära dig om matte, kemi eller fysik? 🤓</h2>
          <p>
            Titta in på vår blogg med massor av inlägg inom dessa olika områden.
          </p>
          <Link to="/blogg/">
            <button className="getStarted">
              <span>Till inlägg</span>
            </button>
          </Link>
        </div>
      </div>
    );
  }
}
